.bg {
  height: 100%;
  width: 100%;
  background-image: url('/Static/bg.png');
  filter: blur(10px);
  position: absolute;
}

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(40, 10, 180, 0.5);
  position: relative;

  .form-container {
    height: 420px;
    width: 600px;
    background-color: #ffffff;
    border-radius: 15px;
    margin-bottom: 15vh;
    overflow: hideen;

    .login-form {
      margin: 40px 120px;

      .title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
      }

      .form {
        margin-top: 30px;
      }
    }
  }
}
