@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.ant-menu-item {
  height: 54px !important;
  line-height: 54px !important;
  font-size: 16px !important;
  padding: 0 20px !important;

  .ant-menu-item-icon {
    font-size: 16px;
  }
}

.bf-container {
  border: 1px solid #ddd;

  .bf-content {
    height: 300px !important;
  }
}
